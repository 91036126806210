import React from "react";
import { AffiliatePlatformType } from "src/generated/graphql-external";

type UtmParams = {
  source?: string | null;
  medium?: string | null;
  campaign?: string | null;
  term?: string | null;
  content?: string | null;
};

type AffiliateParams = {
  tokenString: string;
  affiliatePlatformType: AffiliatePlatformType;
};

type GeneralFeatureContextValue = {
  utmParams: UtmParams;
  affiliateParams: AffiliateParams | null;
  loadingUtmParams: boolean;
};

const UTM_PARAMS = "utm_parameter";
const AFFILIATE_PARAMS = "affiliate_parameter";

const affiliateQueryParamMap: Record<string, AffiliatePlatformType> = {
  rd_code: AffiliatePlatformType.Moshimo,
  cid: AffiliatePlatformType.Afb,
  a8: AffiliatePlatformType.A8,
};

const getAffiliateParams = (
  searchParams: URLSearchParams
): AffiliateParams | null => {
  for (const [param, platformType] of Object.entries(affiliateQueryParamMap)) {
    const value = searchParams.get(param);
    if (value) {
      return {
        tokenString: value,
        affiliatePlatformType: platformType,
      };
    }
  }
  return null;
};

export const GeneralFeatureContext =
  React.createContext<GeneralFeatureContextValue>({
    utmParams: {},
    affiliateParams: null,
    loadingUtmParams: true,
  });

export const GeneralFeatureProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [utmParams, setUtmParams] = React.useState<UtmParams>({
    source: null,
    medium: null,
    campaign: null,
    term: null,
    content: null,
  });
  const [affiliateParams, setAffiliateParams] =
    React.useState<AffiliateParams | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const _searchParams = new URLSearchParams(location.search);

    const searchUtmParams: UtmParams = _searchParams && {
      source: _searchParams.get("utm_source"),
      medium: _searchParams.get("utm_medium"),
      campaign: _searchParams.get("utm_campaign"),
      term: _searchParams.get("utm_term"),
      content: _searchParams.get("utm_content"),
    };
    const searchAffiliateParams: AffiliateParams | null =
      getAffiliateParams(_searchParams);

    // @task 2/7に削除する
    if (searchUtmParams.campaign === "miraiya_2025_kawaguchi") {
      setLoading(false);
      return;
    }

    if (searchUtmParams.source || searchAffiliateParams?.tokenString) {
      if (searchUtmParams.source) {
        setUtmParams(searchUtmParams);
        localStorage.setItem(UTM_PARAMS, JSON.stringify(searchUtmParams));
      }
      if (searchAffiliateParams?.tokenString) {
        setAffiliateParams(searchAffiliateParams);
        localStorage.setItem(
          AFFILIATE_PARAMS,
          JSON.stringify(searchAffiliateParams)
        );
      }
      setLoading(false);
      return;
    }

    let storedUtm: unknown;
    let storedAffiliate: unknown;
    try {
      const localStorageUtmItem = localStorage.getItem(UTM_PARAMS);
      const localStorageAffiliateItem = localStorage.getItem(AFFILIATE_PARAMS);
      if (localStorageUtmItem !== null || localStorageAffiliateItem !== null) {
        if (localStorageUtmItem !== null) {
          storedUtm = JSON.parse(localStorageUtmItem);
        }
        if (localStorageAffiliateItem !== null) {
          storedAffiliate = JSON.parse(localStorageAffiliateItem);
        }
      } else {
        setLoading(false);
        return;
      }
    } catch {
      setLoading(false);
      return;
    }
    if (storedUtm && typeof storedUtm === "object") {
      setUtmParams(storedUtm as UtmParams);
    }
    if (storedAffiliate && typeof storedAffiliate === "object") {
      setAffiliateParams(storedAffiliate as AffiliateParams);
    }
    setLoading(false);
  }, []);

  return (
    <GeneralFeatureContext.Provider
      value={{
        utmParams,
        affiliateParams,
        loadingUtmParams: loading,
      }}
    >
      {children}
    </GeneralFeatureContext.Provider>
  );
};
